$primary: #6312f2;
$hero-body-padding-medium: 5rem 1.5rem;
$tabs-link-active-border-bottom-color: $primary;
$tabs-link-active-color: $primary;
$tabs-toggle-link-active-background-color: $primary;
$tabs-toggle-link-active-border-color: $primary;

@import "bulma";
@import "buefy/src/scss/buefy.scss";

.width-100,
.width-full {
	width: 100%;
}

.mwidth-100 .mwidth-full {
	max-width: 100%;
}

.margin-auto {
	margin: auto;
}

//Creating z-* classes for levels of z-index
@for $num from 0 through 10 {
	.z-#{$num} {
		z-index: #{$num};
	}
}

.modal-content .card {
	background-color: white;
}

.columns {
	@each $name, $value in $breakpoints {
		&.is-flex-row-reverse-#{$name} {
			@include breakpoint($name) {
				flex-direction: row-reverse;
			}
		}
	}
}

            /*
            Size Guide:
            *-0	0
            *-1	0.25rem
            *-2	0.5rem
            *-3	0.75rem
            *-4	1rem
            *-5	1.5rem
            *-6	3rem
            */
            // body {
            //    font-family: 'Roboto', Arial, Helvetica, sans-serif;
            // }

            /* Mobile: up to 768px */
            @media screen and (max-width: 768px) {
               .absolute-center-mobile {
                  position: absolute !important;
                  top: 50% !important;
                  left: 50% !important;
                  transform: translate(-50%, -50%) !important;
               }

               .scale-08-mobile {
                  scale: 0.8;
               }

               .has-bottom-15-mobile {
                  bottom: 15% !important;
               }

               .has-bottom-20-mobile {
                  bottom: 20% !important;
               }

               .mx-auto-mobile {
                  margin-left: auto !important;
                  margin-right: auto !important;
               }

               .mt-0-mobile {
                  margin-top: 0rem !important;
               }

               .mb-0-mobile {
                  margin-bottom: 0rem !important;
               }

               .mt-1-mobile {
                  margin-top: 0.25rem !important;
               }

               .mb-1-mobile {
                  margin-bottom: 0.25rem !important;
               }

               .mt-2-mobile {
                  margin-top: 0.5rem !important;
               }

               .mb-2-mobile {
                  margin-bottom: 0.5rem !important;
               }

               .mt-3-mobile {
                  margin-top: 0.75rem !important;
               }

               .mb-3-mobile {
                  margin-bottom: 0.75rem !important;
               }

               .p-0-mobile {
                  padding: 0rem !important
               }

               .py-0-mobile {
                  padding-top: 0rem !important;
                  padding-bottom: 0rem !important;
               }

               .px-0-mobile {
                  padding-left: 0rem !important;
                  padding-right: 0rem !important;
               }

               .pb-0-mobile {
                  padding-bottom: 0rem !important
               }

               .pt-0-mobile {
                  padding-top: 0rem !important
               }

               .py-1-mobile {
                  padding-top: 0.25rem !important;
                  padding-bottom: 0.25rem !important;
               }

               .px-1-mobile {
                  padding-left: 0.25rem !important;
                  padding-right: 0.25rem !important;
               }

               .p-1-mobile {
                  padding: 0.25rem !important
               }

               .p-2-mobile {
                  padding: 0.5rem !important
               }

               .py-2-mobile {
                  padding-top: 0.5rem !important;
                  padding-bottom: 0.5rem !important;
               }

               .px-2-mobile {
                  padding-left: 0.5rem !important;
                  padding-right: 0.5rem !important;
               }

               .p-3-mobile {
                  padding: 0.75rem !important
               }

               .py-3-mobile {
                  padding-top: 0.75rem !important;
                  padding-bottom: 0.75rem !important;
               }

               .px-3-mobile {
                  padding-left: 0.75rem !important;
                  padding-right: 0.75rem !important;
               }

               .p-4-mobile {
                  padding: 1rem !important
               }

               .py-4-mobile {
                  padding-top: 1rem !important;
                  padding-bottom: 1rem !important;
               }

               .px-4-mobile {
                  padding-left: 1rem !important;
                  padding-right: 1rem !important;
               }

               .p-5-mobile {
                  padding: 1.5rem !important
               }

               .py-5-mobile {
                  padding-top: 1.5rem !important;
                  padding-bottom: 1.5rem !important;
               }

               .px-5-mobile {
                  padding-left: 1.5rem !important;
                  padding-right: 1.5rem !important;
               }

               .p-6-mobile {
                  padding: 3rem !important
               }

               .py-6-mobile {
                  padding-top: 3rem !important;
                  padding-bottom: 3rem !important;
               }

               .px-6-mobile {
                  padding-left: 3rem !important;
                  padding-right: 3rem !important;
               }

               .min-height-40vh-mobile {
                  min-height: calc(40vh - 3.25rem) !important;
               }

               .min-height-50vh-mobile {
                  min-height: calc(50vh - 3.25rem) !important;
               }

               .min-height-60vh-mobile {
                  min-height: calc(60vh - 3.25rem) !important;
               }

               .min-height-3rem-mobile {
                  min-height: 3rem !important;
               }

               .max-height-60vh-mobile {
                  max-height: 60vh !important;
               }

               .max-height-70vh-mobile {
                  max-height: 70vh !important;
               }

               .max-height-80vh-mobile {
                  max-height: 80vh !important;
               }

               .max-height-90vh-mobile {
                  max-height: 90vh !important;
               }

               .has-text-underline-and-overline-mobile {
                  text-decoration: overline underline 2px;
                  text-underline-offset: 5px;
               }
  .m-0-mobile {
     margin: 0px !important;
  }

  .mx-0-mobile {
     margin-left: 0px !important;
     margin-right: 0px !important;
  }

  .my-0-mobile {
     margin-top: 0px !important;
     margin-bottom: 0px !important;
  }            }

            /* Tablet: from 769px */
            @media screen and (min-width: 769px) {
               .absolute-center-tablet {
                  position: absolute !important;
                  top: 50% !important;
                  left: 50% !important;
                  transform: translate(-50%, -50%) !important;
               }

               .ml-auto-tablet {
                  margin-left: auto !important;
               }

               .p-0-tablet {
                  padding: 0rem !important
               }

               .py-0-tablet {
                  padding-top: 0rem !important;
                  padding-bottom: 0rem !important;
               }

               .px-0-tablet {
                  padding-left: 0rem !important;
                  padding-right: 0rem !important;
               }

               .pb-0-tablet {
                  padding-bottom: 0rem !important
               }

               .pt-0-tablet {
                  padding-top: 0rem !important
               }

               .p-1-tablet {
                  padding: 0.25rem !important
               }

               .p-2-tablet {
                  padding: 0.5rem !important
               }

               .p-3-tablet {
                  padding: 0.75rem !important
               }

               .p-4-tablet {
                  padding: 1rem !important
               }

               .p-5-tablet {
                  padding: 1.5rem !important
               }

               .p-6-tablet {
                  padding: 3rem !important
               }

               .hero-right-area-styling-tablet {
                  margin-left: auto;
                  width: 75%;
               }
            }

            /* Desktop: from 1024px */
            @media screen and (min-width: 1024px) {
               .ml-auto-desktop {
                  margin-left: auto !important;
               }

               .has-top-70-desktop {
                  top: 70% !important;
               }

               .min-height-40vh-desktop {
                  min-height: calc(40vh) !important;
               }

               .min-height-50vh-desktop {
                  min-height: calc(50vh) !important;
               }

               .min-height-60vh-desktop {
                  min-height: calc(60vh) !important;
               }

               .min-height-70vh-desktop {
                  min-height: calc(70vh) !important;
               }

               .min-height-80vh-desktop {
                  min-height: calc(80vh) !important;
               }

               .mt-0-desktop {
                  margin-top: 0rem !important;
               }

               .mb-0-desktop {
                  margin-bottom: 0rem !important;
               }

               .mt-1-desktop {
                  margin-top: 0.25rem !important;
               }

               .mb-1-desktop {
                  margin-bottom: 0.25rem !important;
               }

               .mt-2-desktop {
                  margin-top: 0.5rem !important;
               }

               .mb-2-desktop {
                  margin-bottom: 0.5rem !important;
               }

               .mt-3-desktop {
                  margin-top: 0.75rem !important;
               }

               .mb-3-desktop {
                  margin-bottom: 0.75rem !important;
               }

               .mt-3-desktop {
                  margin-top: 0.75rem !important;
               }

               .mb-3-desktop {
                  margin-bottom: 0.75rem !important;
               }

               .p-0-desktop {
                  padding: 0rem !important
               }

               .py-0-desktop {
                  padding-top: 0rem !important;
                  padding-bottom: 0rem !important;
               }

               .px-0-desktop {
                  padding-left: 0rem !important;
                  padding-right: 0rem !important;
               }

               .pb-0-desktop {
                  padding-bottom: 0rem !important
               }

               .pt-0-destop {
                  padding-top: 0rem !important
               }

               .p-1-desktop {
                  padding: 0.25rem !important
               }

               .p-2-desktop {
                  padding: 0.5rem !important
               }

               .p-3-desktop {
                  padding: 0.75rem !important
               }

               .p-4-desktop {
                  padding: 1rem !important
               }

               .p-5-desktop {
                  padding: 1.5rem !important
               }

               .p-6-desktop {
                  padding: 3rem !important
               }

               .py-5-desktop {
                  padding-top: 1.5rem !important;
                  padding-bottom: 1.5rem !important;
               }

               .py-6-desktop {
                  padding-top: 3rem !important;
                  padding-bottom: 3rem !important;
               }

               .px-0-desktop {
                  padding-left: 0rem !important;
                  padding-right: 0rem !important;
               }

               .px-1-desktop {
                  padding-left: 0.25rem !important;
                  padding-right: 0.25rem !important;
               }

               .px-2-desktop {
                  padding-left: 0.5rem !important;
                  padding-right: 0.5rem !important;
               }

               .px-3-desktop {
                  padding-left: 0.75rem !important;
                  padding-right: 0.75rem !important;
               }

               .px-4-desktop {
                  padding-left: 1rem !important;
                  padding-right: 1rem !important;
               }

               .px-5-desktop {
                  padding-left: 1.5rem !important;
                  padding-right: 1.5rem !important;
               }

               .px-6-desktop {
                  padding-left: 3rem !important;
                  padding-right: 3rem !important;
               }
            }

            /* Widescreen: from 1216px */
            @media screen and (min-width: 1216px) {}

            /* Full HD: from 1408px */
            @media screen and (min-width: 1408px) {}


      /*
         Size Guide:
         *-0	0
         *-1	0.25rem
         *-2	0.5rem
         *-3	0.75rem
         *-4	1rem
         *-5	1.5rem
         *-6	3rem
         */
      // body {
      //    font-family: Arial, Helvetica, sans-serif;
      // }

      .mx-auto {
         margin-left: auto !important;
         margin-right: auto !important;
      }

      /* Mobile: up to 768px */
      @media screen and (max-width: 768px) {
         .h-2_5rem-mobile {
            height: 2.5rem !important;
         }

         .h-2rem-mobile {
            height: 2rem !important;
         }

         .w100-mobile {
            width: 100% !important;
         }

         .w40-mobile {
            width: 40% !important;
         }

         .w60-mobile {
            width: 60% !important;
         }

         .w-1rem-mobile {
            width: 1rem !important;
         }

         .w-2rem-mobile {
            width: 2rem !important;
         }

         .w-3rem-mobile {
            width: 3rem !important;
         }

         .w-4rem-mobile {
            width: 4rem !important;
         }

         .w-5rem-mobile {
            width: 5rem !important;
         }
         .absolute-center-mobile {
            position: absolute !important;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
         }

         .scale-08-mobile {
            scale: 0.8;
         }

         .has-bottom-15-mobile {
            bottom: 15% !important;
         }

         .has-bottom-20-mobile {
            bottom: 20% !important;
         }

         .mx-auto-mobile {
            margin-left: auto !important;
            margin-right: auto !important;
         }

         .mt-0-mobile {
            margin-top: 0rem !important;
         }

         .mb-0-mobile {
            margin-bottom: 0rem !important;
         }

         .mt-1-mobile {
            margin-top: 0.25rem !important;
         }

         .mb-1-mobile {
            margin-bottom: 0.25rem !important;
         }

         .mt-2-mobile {
            margin-top: 0.5rem !important;
         }

         .mb-2-mobile {
            margin-bottom: 0.5rem !important;
         }

         .mt-3-mobile {
            margin-top: 0.75rem !important;
         }

         .mb-3-mobile {
            margin-bottom: 0.75rem !important;
         }

         .p-0-mobile {
            padding: 0rem !important
         }

         .py-0-mobile {
            padding-top: 0rem !important;
            padding-bottom: 0rem !important;
         }

         .px-0-mobile {
            padding-left: 0rem !important;
            padding-right: 0rem !important;
         }

         .pb-0-mobile {
            padding-bottom: 0rem !important
         }

         .pt-0-mobile {
            padding-top: 0rem !important
         }

         .py-0-mobile {
            padding-top: 0rem !important;
            padding-bottom: 0rem !important;
         }

         .px-0-mobile {
            padding-left: 0rem !important;
            padding-right: 0rem !important;
         }

         .py-1-mobile {
            padding-top: 0.25rem !important;
            padding-bottom: 0.25rem !important;
         }

         .px-1-mobile {
            padding-left: 0.25rem !important;
            padding-right: 0.25rem !important;
         }

         .p-1-mobile {
            padding: 0.25rem !important
         }

         .p-2-mobile {
            padding: 0.5rem !important
         }

         .py-2-mobile {
            padding-top: 0.5rem !important;
            padding-bottom: 0.5rem !important;
         }

         .px-2-mobile {
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
         }

         .p-3-mobile {
            padding: 0.75rem !important
         }

         .py-3-mobile {
            padding-top: 0.75rem !important;
            padding-bottom: 0.75rem !important;
         }

         .px-3-mobile {
            padding-left: 0.75rem !important;
            padding-right: 0.75rem !important;
         }

         .p-4-mobile {
            padding: 1rem !important
         }

         .py-4-mobile {
            padding-top: 1rem !important;
            padding-bottom: 1rem !important;
         }

         .px-4-mobile {
            padding-left: 1rem !important;
            padding-right: 1rem !important;
         }

         .p-5-mobile {
            padding: 1.5rem !important
         }

         .py-5-mobile {
            padding-top: 1.5rem !important;
            padding-bottom: 1.5rem !important;
         }

         .px-5-mobile {
            padding-left: 1.5rem !important;
            padding-right: 1.5rem !important;
         }

         .p-6-mobile {
            padding: 3rem !important
         }

         .pt-6-mobile {
            padding-top: 3rem !important
         }

         .mt-6-mobile {
            margin-top: 3rem !important
         }

         .py-6-mobile {
            padding-top: 3rem !important;
            padding-bottom: 3rem !important;
         }

         .px-6-mobile {
            padding-left: 3rem !important;
            padding-right: 3rem !important;
         }

         .min-height-40vh-mobile {
            min-height: calc(40vh - 3.25rem) !important;
         }

         .min-height-50vh-mobile {
            min-height: calc(50vh - 3.25rem) !important;
         }

         .min-height-60vh-mobile {
            min-height: calc(60vh - 3.25rem) !important;
         }

         .min-height-3rem-mobile {
            min-height: 3rem !important;
         }

         .max-height-60vh-mobile {
            max-height: 60vh !important;
         }

         .max-height-70vh-mobile {
            max-height: 70vh !important;
         }

         .max-height-80vh-mobile {
            max-height: 80vh !important;
         }

         .max-height-90vh-mobile {
            max-height: 90vh !important;
         }

         .has-text-underline-and-overline-mobile {
            text-decoration: overline underline 2px;
            text-underline-offset: 5px;
         }
      }

      /* Tablet: from 769px */
      @media screen and (min-width: 769px) {
         .absolute-center-tablet {
            position: absolute !important;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
         }

         .ml-auto-tablet {
            margin-left: auto !important;
         }

         .p-0-tablet {
            padding: 0rem !important
         }

         .py-0-tablet {
            padding-top: 0rem !important;
            padding-bottom: 0rem !important;
         }

         .px-0-tablet {
            padding-left: 0rem !important;
            padding-right: 0rem !important;
         }

         .pb-0-tablet {
            padding-bottom: 0rem !important
         }

         .pt-0-tablet {
            padding-top: 0rem !important
         }

         .p-1-tablet {
            padding: 0.25rem !important
         }

         .p-2-tablet {
            padding: 0.5rem !important
         }

         .p-3-tablet {
            padding: 0.75rem !important
         }

         .p-4-tablet {
            padding: 1rem !important
         }

         .p-5-tablet {
            padding: 1.5rem !important
         }

         .p-6-tablet {
            padding: 3rem !important
         }

         .px-4-tablet {
            padding-left: 1rem !important;
            padding-right: 1rem !important;
         }

         .hero-right-area-styling-tablet {
            margin-left: auto;
            width: 75%;
         }
      }

      /* Desktop: from 1024px */
      @media screen and (min-width: 1024px) {
         .ml-auto-desktop {
            margin-left: auto !important;
         }

         .has-top-70-desktop {
            top: 70% !important;
         }

         .min-height-40vh-desktop {
            min-height: calc(40vh) !important;
         }

         .min-height-50vh-desktop {
            min-height: calc(50vh) !important;
         }

         .min-height-60vh-desktop {
            min-height: calc(60vh) !important;
         }

         .min-height-70vh-desktop {
            min-height: calc(70vh) !important;
         }

         .min-height-80vh-desktop {
            min-height: calc(80vh) !important;
         }

         .mt-0-desktop {
            margin-top: 0rem !important;
         }

         .mb-0-desktop {
            margin-bottom: 0rem !important;
         }

         .mt-1-desktop {
            margin-top: 0.25rem !important;
         }

         .mb-1-desktop {
            margin-bottom: 0.25rem !important;
         }

         .mt-2-desktop {
            margin-top: 0.5rem !important;
         }

         .mb-2-desktop {
            margin-bottom: 0.5rem !important;
         }

         .mt-3-desktop {
            margin-top: 0.75rem !important;
         }

         .mb-3-desktop {
            margin-bottom: 0.75rem !important;
         }

         .mt-3-desktop {
            margin-top: 0.75rem !important;
         }

         .mb-3-desktop {
            margin-bottom: 0.75rem !important;
         }

         .p-0-desktop {
            padding: 0rem !important
         }

         .py-0-desktop {
            padding-top: 0rem !important;
            padding-bottom: 0rem !important;
         }

         .px-0-desktop {
            padding-left: 0rem !important;
            padding-right: 0rem !important;
         }

         .pb-0-desktop {
            padding-bottom: 0rem !important
         }

         .pt-0-desktop {
            padding-top: 0rem !important
         }

         .p-1-desktop {
            padding: 0.25rem !important
         }

         .p-2-desktop {
            padding: 0.5rem !important
         }

         .p-3-desktop {
            padding: 0.75rem !important
         }

         .pl-3-desktop {
            padding-left: 0.75rem !important
         }

         .pr-3-desktop {
            padding-right: 0.75rem !important
         }

         .pt-3-desktop {
            padding-top: 0.75rem !important
         }

         .pb-3-desktop {
            padding-bottom: 0.75rem !important
         }

         .pt-4-desktop {
            padding-top: 1rem !important
         }

         .p-4-desktop {
            padding: 1rem !important
         }

         .p-5-desktop {
            padding: 1.5rem !important
         }

         .p-6-desktop {
            padding: 3rem !important
         }

         .py-5-desktop {
            padding-top: 1.5rem !important;
            padding-bottom: 1.5rem !important;
         }

         .py-6-desktop {
            padding-top: 3rem !important;
            padding-bottom: 3rem !important;
         }

         .px-0-desktop {
            padding-left: 0rem !important;
            padding-right: 0rem !important;
         }

         .px-1-desktop {
            padding-left: 0.25rem !important;
            padding-right: 0.25rem !important;
         }

         .px-2-desktop {
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
         }

         .px-3-desktop {
            padding-left: 0.75rem !important;
            padding-right: 0.75rem !important;
         }

         .px-4-desktop {
            padding-left: 1rem !important;
            padding-right: 1rem !important;
         }

         .px-5-desktop {
            padding-left: 1.5rem !important;
            padding-right: 1.5rem !important;
         }

         .px-6-desktop {
            padding-left: 3rem !important;
            padding-right: 3rem !important;
         }

         .w100-desktop {
            width: 100%;
         }
      }

      /* Widescreen: from 1216px */
      @media screen and (min-width: 1216px) {}

      /* Full HD: from 1408px */
      @media screen and (min-width: 1408px) {}

